import React from 'react'
import { documentMask, goToWindow, isNullOrEmpty, queryString } from '../../shared/utils'

class ParkCardView extends React.Component {

    render() {
        return (
            <>
                {
                    this.props.park &&
                    <div className="col-lg-12 mt-3">
                        <div className="alert alert-secondary">
                            <span className="badge text-bg-secondary">Estabelecimento</span> <br />
                            <h4>{this.props.park.name}</h4>
                            {documentMask(this.props.park.document)} - {this.props.park.phone} <br />
                            {this.props.park.address.street}, {this.props.park.address.number} - {this.props.park.address.zipcode} <br />
                            {this.props.park.times}
                        </div>
                    </div>
                }
            </>
        )
    }
}

class NoteCardView extends React.Component {

    _colorAlertByStatus = (status) => {
        switch (status) {
            case "NOTE_PROCESSING": return "alert alert-warning"
            case "NOTE_ERROR": return "alert alert-danger"
            case "NOTE_CANCELED": return "alert alert-danger"
            case "NOTE_GENERATED": return "alert alert-success"
            default: return "badge text-bg-secondary mb-2"
        }
    }

    _colorAlertBadgeByStatus = (status) => {
        switch (status) {
            case "NOTE_PROCESSING": return "badge text-bg-secondary mb-2"
            case "NOTE_ERROR": return "badge text-bg-danger mb-2"
            case "NOTE_CANCELED": return "badge text-bg-danger mb-2"
            case "NOTE_GENERATED": return "badge text-bg-success mb-2"
            default: return "badge text-bg-secondary mb-2"
        }
    }

    render() {
        return (
            <>
                {
                    this.props.note &&
                    <div className="col-lg-12 mt-3">
                        <div className={this._colorAlertByStatus(this.props.note.currentStatus)}>
                            {
                                (this.props.note.currentStatus === "NOTE_CANCELED" ||
                                    this.props.note.currentStatus === "NOTE_GENERATED") &&
                                <>
                                    <span className={this._colorAlertBadgeByStatus(this.props.note.currentStatus)}>{this.props.note.noteType}</span> <br />
                                </>
                            }
                            {
                                !isNullOrEmpty(this.props.note.name) &&
                                <>
                                    <div>Nome:</div>
                                    <div className='text-bold'>{this.props.note.name}</div>
                                </>
                            }
                            {
                                !isNullOrEmpty(this.props.note.document) &&
                                <>
                                    <div>CPF ou CNPJ:</div>
                                    <div className='text-bold'>{documentMask(this.props.note.document)}</div>
                                </>
                            }
                            {
                                !isNullOrEmpty(this.props.note.email) &&
                                <>
                                    <div>E-mail:</div>
                                    <div className='text-bold'>{this.props.note.email}</div>
                                </>
                            }
                            {
                                !isNullOrEmpty(this.props.note.currentStatus) &&
                                <div className="col-lg-12 text-center mt-2">
                                    {
                                        this.props.note.currentStatus === "NOTE_PROCESSING" &&
                                        <div>
                                            <h6>Estamos gerando a sua nota fiscal, isso pode levar até 2 dias.</h6>
                                        </div>
                                    }
                                    {
                                        this.props.note.currentStatus === "NOTE_ERROR" &&
                                        <div>
                                            <h6>Não foi possível gerar a sua nota fiscal, entre em contato com o estabelecimento.</h6>
                                        </div>
                                    }
                                    {
                                        this.props.note.currentStatus === "NOTE_CANCELED" &&
                                        <a href={this.props.note.pdf} target="_blank" rel="noreferrer" className="btn btn-danger btn-lg w-100 text-white">
                                            <i className="fa-solid fa-file-pdf mx-2" />
                                            Visualizar nota cancelada
                                        </a>
                                    }
                                    {
                                        this.props.note.currentStatus === "NOTE_GENERATED" &&
                                        <a href={this.props.note.pdf} target="_blank" rel="noreferrer" className="btn btn-success btn-lg w-100 text-white">
                                            <i className="fa-solid fa-file-pdf mx-2" />
                                            Visualizar nota
                                        </a>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                }
            </>
        )
    }
}

class ContactInfoCardView extends React.Component {

    render() {
        return (
            <>
                {
                    this.props.contactInfo ?
                        <div className="col-lg-12">
                            <div className="alert alert-success">
                                <span role="button" className="badge text-bg-success" onClick={(e) => goToWindow(`/contato/cadastrar/?id=${queryString("id")}`)}>
                                    Dados de contato
                                    <i className="fas fa-edit mx-1" />
                                </span> <br />
                                <h5>{this.props.contactInfo.name}</h5>
                                {
                                    !isNullOrEmpty(this.props.contactInfo.email) &&
                                    <div>
                                        E-mail: {this.props.contactInfo.email}
                                    </div>
                                }
                                {
                                    !isNullOrEmpty(this.props.contactInfo.document) &&
                                    <div>
                                        Documento: {documentMask(this.props.contactInfo.document)}
                                    </div>
                                }
                                {
                                    !isNullOrEmpty(this.props.contactInfo.phone) &&
                                    <div>
                                        Celular: {this.props.contactInfo.phone}
                                    </div>
                                }
                                {this.props.children}
                            </div>
                        </div > : <>
                            {this.props.children}
                        </>
                }
            </>
        )
    }
}

export {
    ParkCardView,
    ContactInfoCardView,
    NoteCardView
}